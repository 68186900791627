import React, { useEffect } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import MaxWidth from 'components/functional/maxWidth'
import Layout from 'components/global/layout'
import PageHeader from 'components/shared/pageHeader'
import { media } from 'utils/Media'

const SpektrixStyles = styled.section`
  padding: 3rem 0;
  background-color: var(--black);
  @media ${media.md} {
    padding: 5rem 0;
  }
  > section {
    width: 100%;
    background-color: var(--white);
    padding: 2.5em;
    z-index: 2;
    position: relative;
  }
`

const LoginPage = ({ data }) => {
  const mob = data.mobHeaderImg
  const desk = data.deskHeaderImg

  return (
    <Layout>
      <PageHeader mobileHeader={mob} desktopHeader={desk} />
      <MaxWidth $black>
        <SpektrixStyles>
          <section>
            <iframe
              title="SpektrixIFrame"
              id="SpektrixIFrame"
              src={`https://tickets.the-mousetrap.co.uk/${process.env.GATSBY_EVENT_NAME}/website/Secure/MyAccount.aspx?resize=true`}
              frameBorder="0"
              style={{ width: '100%', height: '1000px' }}
            />
            {/* <iframe
              title="SpektrixIFrame"
              id="SpektrixIFrame"
              src={`https://tickets.the-mousetrap.co.uk/${process.env.GATSBY_EVENT_NAME}/website/secure/LoginLogout.aspx?resize=true`}
              frameBorder="0"
              style={{ width: '100%', height: '1000px' }}
            /> */}
          </section>
        </SpektrixStyles>
      </MaxWidth>
    </Layout>
  )
}

export default LoginPage

export const CastCreativeQuery = graphql`
  query {
    mobHeaderImg: file(name: { eq: "cast-creative-mobile" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    deskHeaderImg: file(name: { eq: "cast-creative-header" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
